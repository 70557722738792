/**
 *
 * Counter
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import './counter.scss';

function Counter({
  value,
  decrement,
  increment,
  size,
  maximumValue,
  minimumValue,
  disabledDecrement,
  disabledIncrement,
  roundButtons,
  blackText,
}) {
  const [count, setCount] = useState(value);

  function handleIncrement() {
    setCount((prevCount) => prevCount + 1);
  }

  function handleDecrement() {
    setCount((prevCount) => prevCount - 1);
  }

  return (
    <div className={`counter-container ${size}`}>
      <Button
        disabled={count === 0 || disabledDecrement}
        onPress={() => {
          if (count <= minimumValue || disabledDecrement) {
            return;
          }
          decrement();
          handleDecrement();
        }}
        variant="white"
        className={`counter-button ${roundButtons ? 'round' : ''}`}
      >
        <i className="icon-minus minus" />
      </Button>

      <span className={`counter-text ${blackText ? 'black' : ''}`}>{count}</span>

      <Button
        disabled={disabledIncrement}
        onPress={() => {
          if (count >= maximumValue || disabledIncrement) {
            return;
          }
          increment();
          handleIncrement();
        }}
        className={`counter-button ${roundButtons ? 'round' : ''}`}
      >
        <i className="icon-Add plus" />
      </Button>
    </div>
  );
}

Counter.propTypes = {
  value: PropTypes.number,
  maximumValue: PropTypes.number,
  minimumValue: PropTypes.number,
  decrement: PropTypes.func,
  increment: PropTypes.func,
  size: PropTypes.string,
  disabledIncrement: PropTypes.bool,
  disabledDecrement: PropTypes.bool,
  roundButtons: PropTypes.bool,
  blackText: PropTypes.bool,
};

Counter.defaultProps = {
  roundButtons: false,
  blackText: false,
};

export default memo(Counter);
