/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CurrencyText from '../../../components/UI/CurrencyText';
import Counter from '../../../components/UI/Counter';
import './optionPanel2.scss';

const OptionPanelControlType2 = ({ parentOption, parentOptionItem, option, childOption, onChange }) => {
  const { controlType, items } = option || {};

  const optionItem = items?.[0] || {};

  const { minQuantity, maxQuantity, title, pricePerProductOption, standardQuantity } = optionItem;

  const [selectedQuantity, setSelectedQuantity] = useState(standardQuantity);

  if (controlType !== 2) return null;

  const extraCount = Math.max(selectedQuantity - standardQuantity, 0);

  const extraPrice = pricePerProductOption * extraCount;

  const handleStepperValueChange = (quantityFromStepper) => {
    setSelectedQuantity(quantityFromStepper);
    const extraPrice2 = pricePerProductOption * Math.max(quantityFromStepper - standardQuantity, 0);
    onChange(parentOption, parentOptionItem, option, optionItem, childOption, quantityFromStepper, extraPrice2);
  };

  return (
    <div className="option-panel-2">
      <b>{title}</b>
      {extraCount > 0 && (
        <div>
          + <CurrencyText>{extraPrice}</CurrencyText>
        </div>
      )}
      <Counter
        size="sm"
        value={selectedQuantity}
        increment={() => handleStepperValueChange(selectedQuantity + 1)}
        decrement={() => handleStepperValueChange(selectedQuantity - 1)}
        minimumValue={minQuantity}
        maximumValue={maxQuantity}
      />
      {/* <Stepper
        minimumValue={minQuantity}
        maximumValue={maxQuantity}
        outerValue={selectedQuantity}
        outerSetValue={handleStepperValueChange}
      /> */}
    </div>
  );
};

OptionPanelControlType2.propTypes = {
  parentOptionItem: PropTypes.object,
  parentOption: PropTypes.object,
  option: PropTypes.object,
  childOption: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionPanelControlType2;
