import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

function Button({ onPress, children, variant, className, block, size, radius, ...rest }) {
  const btnBlock = block ? `btn-block` : '';

  const onClickButton = (event) => {
    event.currentTarget.blur();
    onPress();
  };

  return (
    <button
      type="button"
      {...rest}
      className={`button button-${variant} ${btnBlock} button-${size} button-radius-${radius} ${className}`}
      onClick={onClickButton}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  size: 'md',
  radius: 'high',
  variant: 'danger',
};

Button.propTypes = {
  size: PropTypes.string, // md: 44px, sm: 38px
  variant: PropTypes.string, // 'white' || 'danger' || 'danger-link',
  radius: PropTypes.string, // low: 4px, high: 10px
  className: PropTypes.string,
  block: PropTypes.bool,
  onPress: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default memo(Button);
